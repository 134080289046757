import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'fruit_snacks',
  formId: 'DK%20Lunch',
  title: 'Fruit & Snacks',
  subTitle: 'Brace yourself for a true fruit and snack attack!',
  icon: '/icons/fruit.svg',
  image: '/assets/images/sunrise/fruit_snacks_supermercat.png',
  theme: 'sunrise-fruit_snacks',
  sections: [
    {
      sectionId: 'hero',
      label: 'Fruit & Snacks',
      title: 'Brace yourself for a true fruit and snack attack!',
      description:
        'Boost the office energy with tasteful snacks and fruit... and become the office hero. All snacks are carefully selected according to strict requirements for taste, quality and accountability.',
      image: '/assets/images/sunrise/fruit_snacks_supermercat.png',
      icon: '/icons/fruit.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'Life is too short not to snack.',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/fruit_snacks/service_1.svg',
          title: 'Savoury snacks',
          description: '',
        },
        {
          icon: '/assets/images/services/fruit_snacks/service_2.svg',
          title: 'Snack bars',
          description: '',
        },
        {
          icon: '/assets/images/services/fruit_snacks/service_3.svg',
          title: 'Chocolate and treats ',
          description: '',
        },
        {
          icon: '/assets/images/services/fruit_snacks/service_4.svg',
          title: 'Fruit',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Getting snacks is easy!',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/fruit_snacks/journey_1.svg',
          title: 'Tell us what you like',
          description: 'We won’t judge. Share your deepest cravings!',
        },
        {
          icon: '/assets/images/services/fruit_snacks/journey_2.svg',
          title: 'Receive proposal',
          description: 'We’ll create a detailed proposal to make sure all your employees have something to chew.',
        },
        {
          icon: '/assets/images/services/fruit_snacks/journey_3.svg',
          title: 'Accept!',
          description: 'That’s it! Energy on the way!',
        },
        {
          icon: '/assets/images/services/fruit_snacks/journey_4.svg',
          title: 'Munch',
          description: 'Yum yum yum!!',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies love some snacks from Good Monday!',
      description: '',
      image: '/assets/images/services/fruit_snacks/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-bulb-outline',
          title: 'Inspiration for snacks and fruit',
          description: 'We have everything that’s out there. Mix it up with us!',
        },
        {
          icon: 'eva eva-smiling-face-outline',
          title: 'Healthy alternatives',
          description: 'We have all the stuff that’s good for you!',
        },
        {
          icon: 'eva eva-activity-outline',
          title: 'Flexibility',
          description:
            'If you want to change your variety of snacks and fruit then just let us know. We’ll adjust ASAP!',
        },
      ],
    },
    {
      sectionId: 'cta',
      title:
        'Did you know that Good Monday clients tells us that fruit at the office stops them from snacking on the naughty stuff?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
